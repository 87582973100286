import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"

import CTABlock from "../../components/blocks/cta_block"

const PositionsPage = ({ data }) => {
  const page = data.wagtail.page

  let ancestor = { children: [], button: {} }
  data.wagtail.page.ancestors.forEach(item => {
    if ("children" in item) {
      ancestor = item
    }
  })
  console.log(page)

  return (
    <>
      <Layout
        isContent={true}
        subMenu={ancestor.children}
        button={ancestor.button}
        parent={"officials"}
        siteTitle={page.title}
      >
        <section className="breadcrumb-area breadcrumb-sub-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb nav-breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/officials">Officials</Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section className="main-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1>{page.title}</h1>
                <p>{page.descriptionSimple}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div
                  className="c-title-content mb-80"
                  dangerouslySetInnerHTML={{
                    __html: data.wagtail.page.bodySafe,
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="committees-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="evrnt-logation-contact">
                  {page.children.map(item => {
                    const url = `/officials/${page.slug}/${item.slug}`
                    return (
                      <div className="evrnt-item" key={item.slug}>
                        <Link className="name" to={url}>
                          {item.title}
                        </Link>
                        <p className="extra">{item.descriptionSimple}</p>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
        <CTABlock data={page.fullContent[0]} />
      </Layout>
    </>
  )
}

PositionsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query($id: Int) {
    wagtail {
      page(id: $id) {
        ... on PositionsPage {
          id
          title
          slug
          descriptionSimple
          bodySafe
          fullContent {
            ... on CTABlock {
              id
              title
              description
              orientation
              url
              urlName
              image {
                src
                width
                height
              }
            }
          }
          children {
            ... on PositionPage {
              title
              slug
              descriptionSimple
            }
          }
        }
        ancestors {
          ... on OfficialsPage {
            slug
            children {
              title
              slug
              ... on SimplePage {
                live
                isProtected
              }
              ... on PositionsPage {
                live
                isProtected
              }
              ... on OfficialPage {
                live
                isProtected
              }
              ... on BulletinsPage {
                live
                isProtected
              }
            }
            button {
              text
              name
              url
            }
          }
        }
      }
    }
  }
`

export default PositionsPage
